import {useState} from 'react';

import './fileupload.css';

interface FileUploadProps {
    entryId?: string;
    ks: string;
    thumbTag: string;
    limit: number;
}

export default function FileUpload(props: FileUploadProps){
	const [selectedFile, setSelectedFile] = useState();
	// const [isFilePicked, setIsFilePicked] = useState(false);
    const [filesUploaded, setFilesUploaded] = useState(0);

	const changeHandler = (event: any) => {
		setSelectedFile(event.target.files[0]);
        console.log(event.target.files[0])
		// setIsFilePicked(true);
	};

	const handleSubmission = () => {
        const formData = new FormData();
        if(selectedFile !== undefined && props.entryId) {
            formData.append('ks', props.ks);
            formData.append('entryId', props.entryId)
            formData.append('format', '1')
            formData.append('thumbAsset:objectType', 'KalturaThumbAsset');
            formData.append('thumbAsset:tags', props.thumbTag);
            fetch('https://www.kaltura.com/api_v3/service/thumbAsset/action/add', {
                method: 'post',
                body: formData,
            }).then( (result) => {
                result.json().then( (thumbAssetCreated) => {
                    const formData2 = new FormData();
                    formData2.append('ks', props.ks);
                    formData2.append('id', thumbAssetCreated.id);
                    formData2.append('format', '1')
                    formData2.append('contentResource:objectType', 'KalturaUploadedFileResource');
                    formData2.append('contentResource:fileData', selectedFile);
                    fetch('https://www.kaltura.com/api_v3/service/thumbAsset/action/setContent', {
                        method: 'post',
                        body: formData2,
                    }).then( ( result ) => {
                        result.json().then( (data ) => {
                            console.log(data)
                            setFilesUploaded(filesUploaded+1);
                        })
                    })
                }) 
            } )
        }
    }

	return (
        <div>
            {  (props.entryId && filesUploaded < props.limit) && 
            <div>
                <div>
                    העלתם {filesUploaded} מתוך {props.limit} עבור שאלה זו
                </div>
                <input type="file" name="file" onChange={changeHandler} />
                <div>
                    <button className='active' onClick={handleSubmission}>שלח</button>
                </div>
            </div>
            }
            {
                (filesUploaded >= props.limit) && 
                <div>כמות תמונות מקסימלית עלתה.</div>
            }
            { (!props.entryId) && <div>לא ניתן להעלות תמונה</div>}
        </div>

	)
}