import BackButton from "../BackButton";
import './credits.css';

const Credits = () => {
    return (
        <div className="credits-page">
            <div>
                Background images created by <a href="https://www.freepik.com/free-photo/modern-luxury-authentic-dining-room-interior-design-with-blank-picture-frame_17597585.htm#page=6&query=Dining%20room&position=20&from_view=author">rawpixel.com</a> on Freepik
            </div>
            <div>
                Images used in Styling Quiz - created by:
                <ul>
                    <li><a href="https://www.freepik.com/author/pikisuperstar">pikisuperstar</a></li>
                    <li><a href="https://www.freepik.com/author/macrovector">macrovector</a></li>
                    <li><a href="https://www.freepik.com/author/ibrandify">ibrandify</a></li>
                    <li><a href="https://www.freepik.com/author/Zlatko-Plamenov">Zlatko_Plamenov</a></li>
                    <li><a href="https://www.freepik.com/author/starline">starline</a></li>
                    <li><a href="https://www.freepik.com/author/lukasdedi">lukasdedi</a></li>
                    <li><a href="https://www.freepik.com/author/GarryKillian">GarryKillian</a></li>
                    <li><a href="https://www.freepik.com/author/renata-s">renata.s</a></li>
                    <li><a href="https://www.freepik.com/">Freepik</a></li>
                    <li><a href="https://www.freepik.com/author/Flatart">Flatart</a></li>
                    <li><a href="https://www.freepik.com/author/Sketchepedia">Sketchepedia</a></li>
                    <li><a href="https://www.freepik.com/author/rawpixel-com">rawpixel.com</a></li>
                    <li><a href="https://www.freepik.com/author/alexandercho">alexandercho</a></li>
                </ul>
            </div>
            <BackButton />
        </div>
    )
}

export default Credits;