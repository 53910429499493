import { Routes, Route } from 'react-router-dom';
import AboutPage from '../pages/about/AboutPage';
import Admin from '../pages/admin/Admin';
import StylingForm from '../pages/stylingform/StylingForm';
import Home from '../pages/home/Home';
import ContactForm from './contactform/ContactForm';
import Credits from './credits/Credits';
// import About from './about/About';
// import Info from './info/Info';
// import InfoButton from './infobutton/InfoButton';
// import ProfilePic from './profilepic/ProfilePic';
// import RectangleLogo from './rectanglelogo/RectangleLogo';
import Terms from './terms/Terms';
import WhatsappButton from './whatsappbutton/WhatsappButton';
import Quizlanding from '../pages/quizlanding/Quizlanding';
import Stylingquiz from '../pages/stylingquiz/Stylingquiz';
import StylingForm2 from '../pages/stylingform2/StylingForm2';

const Main = () => {
    //// commented out as currently not being rendered (passover sale on hold)
    // const homeMobileContent = (
    //     <div>
    //         <ContactForm/>
    //         <RectangleLogo classes='second-logo'/>
    //         <ProfilePic/>
    //         <About/>
    //         <InfoButton mobileLinkHref="#top" desktopLinkHref='/' buttonText="מבצע פסח 2023" />
    //         <WhatsappButton />
    //     </div>
    // )
    return (
        <Routes>
            {/* put the passover sale on hold: */}
            {/* <Route path='/' element={<Home mobileChildren={homeMobileContent}><Info></Info></Home>} /> */}
            <Route path='/' element={<Home><h3>מבצע פסח הסתיים. האתר יתעדכן בקרוב.</h3><WhatsappButton /><ContactForm showButtons={false}/></Home>} />
            <Route path='/contact' element={<Home><ContactForm></ContactForm></Home>} />
            <Route path='/about' element={<AboutPage/>} />
            <Route path='/credits' element={<Home><Credits></Credits></Home>} />
            <Route path='/terms' element={<Home><Terms></Terms></Home>} />
            <Route path='/styling-form' element={<StylingForm></StylingForm>} />
            <Route path='/styl.ing-form' element={<StylingForm2></StylingForm2>} />
            <Route path='/admin' element={<Admin></Admin>} />
            <Route path='/get-styling-quiz' element={<Quizlanding></Quizlanding>} />
            <Route path='/styling-quiz' element={<Stylingquiz />} />
        </Routes>
    );
}
export default Main;