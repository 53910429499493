import StylingWords from './StylingWords';
import Materials from './Materials';
import Colors from './Colors';
import FurnitureDescriptions from './FurnitueDescriptions';

export enum QuestionTypes {
    checkbox = "checkbox",
    radio = "radio",
    textarea = "textarea"
}
export interface Question {
    label: string;
    description?: string;
    name: string;
    required?: boolean;
    type?: QuestionTypes;
    options?: string[];
    section: number;
    requiresImages?: boolean;
    imagesDescription?: string;
}
export const Sections = [
    "מידע כללי",
    "העדפות עיצוביות",
    "נתוני פרוייקט",
    "תמונות",    
]
export const Questions: Question[] = [
    { 
        section: 1,
        label: "שם",
        name: "name"
    },
    { 
        section: 1,
        label: "אימייל",
        name: "email"
    },
    {
        section: 1,
        label: "איזה חלל/חדר אנחנו מעצבים?",
        name: "space"
    },
    {
        section: 1,
        label: "איזה רהיטים צריכים להיות?",
        name: "space-furniture",
        type: QuestionTypes.textarea
    },
    {
        section: 2,
        label: "אילו מהמילים הבאות משקפות את הסגנון שלכם בצורה הטובה ביותר?",
        name: "styling-words",
        options: StylingWords,
        type: QuestionTypes.checkbox,
        requiresImages: true,
        imagesDescription: "לכל אחת מהמילים שבחרתם עבור שאלה זו, לחפש עד שתי תמונות המתארות אותה דרך העיניים שלכם"
    },
    {
        section: 2,
        label: "לאילו חומרים אתם מתחברים / אוהבים?",
        name: "materials", 
        options: Materials,
        type: QuestionTypes.checkbox
    },
    {
        section: 2,
        label: "אילו מהצבעים הבאים אתם !!לא!! אוהבים ו!!לא!! מוכנים שיהיו בחלל?",
        name: "colors-not-liked",
        options: Colors,
        type: QuestionTypes.checkbox
    },
    {
        section: 2,
        label: "אילו מהצבעים הבאים אתם מאוד אוהבים ורוצים שיהיו בחלל?",
        name: "colors-liked",
        options: Colors,
        type: QuestionTypes.checkbox
    },
    {
        section: 2,
        label: "מה מדויק להגיד עליכם? שאתם אוהבים רהיטים...",
        name: "furniture-description",
        options: FurnitureDescriptions,
        type: QuestionTypes.checkbox
    },
    {
        section: 3,
        label: "תקציב משוער לחלל/החדר הזה",
        name: "budget"
    },
    {
        section: 3,
        label: "האם בתקציב נלקח בחשבון עלויות של קבלן/שיפוצניק/צבע?",
        name: "is-budget-net",
        options: [
            "כן",
            "לא",
        ],
        type: QuestionTypes.radio
    },
    {
        section: 3,
        label: "האם נשארים עם ריצוף קיים?",
        name: "flooring",
        options: [
            "כן",
            "לא",
        ],
        type: QuestionTypes.radio
    },
    { 
        section: 3,
        label: "האם נשארים עם דלתות קיימות?",
        name: "doors",
        options: [
            "כן",
            "לא",
        ],
        type: QuestionTypes.radio
    },
    {
        section: 3,
        label: "האם מעדיפים לקנות רהיטים מוכנים או בהזמנה?",
        description: "מוכנים - כמו באיקאה, מה שיש במלאי לוקחים. בהזמנה - ייצור לפי דרישת לקוח.",
        name: "purchase-type",
        options: [
            "רהיטים מוכנים בלבד",
            "רהיטים בהזמנה בלבד",
            "גם וגם"
        ],
        type: QuestionTypes.radio
    },
];

export function getQuestionByName(name: string) {
    return Questions.find( (q: Question) => {
        if(q.name === name) {
            return q;
        }
        return null;
    })
}

export function getQuestionsRequireImages() {
    return Questions.filter( (q) => {
        return (q.requiresImages);
    })
}