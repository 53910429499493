import { useState } from "react";
import InfoButton from "../infobutton/InfoButton";
import WhatsappButton from "../whatsappbutton/WhatsappButton";

import './contactform.css';
interface Props {
    showButtons?: boolean
}
const ContactForm = (props: Props) => {
    const [formSubmitted, setFormSubmitted] = useState(false);


    const submitUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfhrN8vlJxe4jGMG0M6dMxYzIcu7gpWh2hWlOrqayNVBZkvYA/formResponse";
    // TODO - fill background from localstorage
    const background = "TODO!!!!!";
    return (
        <div className="contact-page">
            <div className="contact-title">
                השאירו את פרטיכם ואצור קשר בהקדם
            </div>
            <div className="contact-form">
                {formSubmitted !== true ?
                <form action={submitUrl} method="POST" target="response_frame">
                <div className="field">
                    <label htmlFor="entry.2106849002">שם</label>
                    <input type="text" name="entry.2106849002" />
                </div>
                <div className="field">
                    <label htmlFor="entry.1005637372">טלפון</label>
                    <input type="tel" inputMode="numeric" name="entry.1005637372" pattern="[0-9]*" />
                </div>
                <div className="field">
                    <label htmlFor="entry.1931583914">אימייל</label>
                    <input type="email" name="entry.1931583914" />
                </div>
                <input type="hidden" name="entry.1821064507" value={background} />
                <div className="field">
                    <button onClick={ (e) => { setTimeout(() => {setFormSubmitted(true)}, 3000)}}>שלח</button>
                </div>
                </form>
                :
                <div className="contact-thankyou">
                    הפרטים נקלטו. תודה!
                </div>
                }
            </div>
            <iframe title="gfresponseframe" width="0" height="0" name="response_frame" />
            { (props.showButtons || props.showButtons !== false) && 
            <div className="contact-page-buttons">
                <WhatsappButton classes="contactpage-buttons" />
                <InfoButton mobileLinkHref="#top" desktopLinkHref="/" buttonText="מבצע פסח 2023" classes="contactpage-buttons" />
            </div>}
        </div>
    )
}
export default ContactForm;