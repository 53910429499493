import Backendless from 'backendless'

  /*******************************************************************
  * KalturaAuthService.js
  * Generated by Backendless Corp.
  ********************************************************************/
	
// TODO - check if not needed
// const Utils = {
//   isObject : obj => obj === Object(obj),
//   isString : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'String',
//   isNumber : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Number',
//   isBoolean: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Boolean',
//   isDate   : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Date'
// }

Backendless.APIServices.KalturaAuthService = {
  getKs() { 
    const args = null
      
    return Backendless.APIServices.invoke('KalturaAuthService', 'getKs', args)
  }
}

export default Backendless.APIServices.KalturaAuthService