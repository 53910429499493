import './testimonial.css';

interface Props {
  names?: string;
  children?: JSX.Element;
}

const Testimonial = (props: Props) => {
  return (
    <div className="testimonial">
      { props.children && 
      <div className='testimonial-text'>{props.children}</div> }
      { props.names && 
      <div className='testimonial-names'><h2>{props.names}</h2></div> }
    </div>
  )
}

export default Testimonial;
