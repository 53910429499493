const Colors = [
  'לבן',
'חום',
'כחול',
'ירוק כהה',
'אפור',
'חרדל',
'תכלת',
'ירוק בהיר',
'שחור',
'כסף',
'טורקיז',
'שמנת',
'צהוב',
'זהב',
'סגול כהה',
'בורדו',
'כתום',
'ברונזה',
'סגול לילך',
'ורוד',
  ]

  export default Colors