export enum QuestionType {
    textSelect = "question-type-text-select",
    imageSelect = "question-type-image-select"
}
interface Question {
    title: string,
    type: QuestionType,
    answers: Array<number|string>,
    answersImages?: Array<string>
    fieldId: number
}
const QuizQuestions: Array<Question> = [
    {
        title: "לאיזה שילוב צבעים את הכי מתחברת",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/504534831/urban.jpeg',
            '/images/quiz-images/504534831/ethnic.jpeg',
            '/images/quiz-images/504534831/bohemian.jpeg',
            '/images/quiz-images/504534831/country.jpeg',
            '/images/quiz-images/504534831/classic.jpeg',
            '/images/quiz-images/504534831/gold.jpeg',
            '/images/quiz-images/504534831/scandinavian.jpeg',
            '/images/quiz-images/504534831/modern.jpeg',
            '/images/quiz-images/504534831/industrial.jpeg',
            '/images/quiz-images/504534831/retro.jpeg',
        ],
        fieldId: 504534831,
    },
    {
        title: "הבית שלי הוא המקום בו....",
        type: QuestionType.textSelect,
        answers: [
            "אני מרגישה הכי רגוע",
            "אני מחוברת לעיר",
            "אני מרגישה בחופשה",
            "אני חוזרת בזמן",
            "יש חום, פשטות ונחמה",
            "הילדה הפנימית שלי יוצאת לאור",
            "אני הכי יצירתית",
            "הכי פתוח, מזמין ומואר",
            "אני מרגישה הכי בסטייל",
        ],
        fieldId: 377903672
    },
    {
        title: "תבחרי את ההדפס/טקסטורה שאת הכי מתחברת אליו",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/281780048/1.jpg',
            '/images/quiz-images/281780048/2.jpg',
            '/images/quiz-images/281780048/3.jpg',
            '/images/quiz-images/281780048/4.jpg',
            '/images/quiz-images/281780048/5.jpg',
            '/images/quiz-images/281780048/6.jpg',
            '/images/quiz-images/281780048/7.jpg',
            '/images/quiz-images/281780048/8.jpg',
            '/images/quiz-images/281780048/9.jpg',
            '/images/quiz-images/281780048/10.jpg',
        ],
        fieldId: 281780048,
    },
    {
        title: "מה הכורסה שאת הכי אוהבת",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/1448947481/10.jpg',
            '/images/quiz-images/1448947481/9.jpg',
            '/images/quiz-images/1448947481/8.jpg',
            '/images/quiz-images/1448947481/7.jpg',
            '/images/quiz-images/1448947481/6.jpg',
            '/images/quiz-images/1448947481/5.jpg',
            '/images/quiz-images/1448947481/4.jpg',
            '/images/quiz-images/1448947481/3.jpg',
            '/images/quiz-images/1448947481/1.jpg',
            '/images/quiz-images/1448947481/2.jpg',
        ],
        fieldId: 1448947481,
    },
    {
        title: "החברים שלי יתארו אותי כ....",
        type: QuestionType.textSelect,
        answers: [
            "ישירה ואמינה",
            "איכותית וחדשנית",
            "רגוע וזורמת",
            "יצירתית ומיוחדת",
            "רצינית וביצועיסטית",
            "מתוקה, אדיבה ועדינה",
            "אוהבת טבע והרפתקנית",
            "נשמה ישנה, אוהבת ואכפתית",
            "אצילית ומסוגננת",
            "כיפית ואנרגטית"
        ],
        fieldId: 1166456020
    },
    {
        title: "תמונה שהיית תולה אצלך בבית",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/1515077100/7.jpg',
            '/images/quiz-images/1515077100/8.jpg',
            '/images/quiz-images/1515077100/9.jpg',
            '/images/quiz-images/1515077100/10.jpg',
            '/images/quiz-images/1515077100/6.jpg',
            '/images/quiz-images/1515077100/5.jpg',
            '/images/quiz-images/1515077100/4.jpg',
            '/images/quiz-images/1515077100/2.jpg',
            '/images/quiz-images/1515077100/3.jpg',
            '/images/quiz-images/1515077100/1.jpg',
        ],
        fieldId: 1515077100,
    },
    {
        title: "לאיזה קיר כניסה את הכי נמשכת",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/971311403/10.jpg',
            '/images/quiz-images/971311403/3.jpg',
            '/images/quiz-images/971311403/9.jpg',
            '/images/quiz-images/971311403/8.jpg',
            '/images/quiz-images/971311403/5.jpg',
            '/images/quiz-images/971311403/7.jpg',
            '/images/quiz-images/971311403/6.jpg',
            '/images/quiz-images/971311403/2.jpg',
            '/images/quiz-images/971311403/1.jpg',
            '/images/quiz-images/971311403/4.jpg',
        ],
        fieldId: 971311403,
    },
    {
        title: "מה היחס שלך לפיצ׳יספקס",
        type: QuestionType.textSelect,
        answers: [
            "אני אוהבת!!! תני לי כמה שיותר, יש לי מלא אוספים ופרטי נוי ואני אוהבת שהם מוצגים לראווה",
            "הכל במידה - אוהבת שיש קצת פרטי נוי, דברים שאספתי עם הזמן ויש להם משמעות",
            "מינימליסטי זה אני! כמה שיותר נקי ומסודר בלי בלגן בעין",
        ],
        fieldId: 1332574408
    },
    {
        title: "איזה סט כלים היית קונה עכשיו",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/1798177106/10.jpg',
            '/images/quiz-images/1798177106/9.jpg',
            '/images/quiz-images/1798177106/7.jpg',
            '/images/quiz-images/1798177106/8.jpg',
            '/images/quiz-images/1798177106/4.jpg',
            '/images/quiz-images/1798177106/6.jpg',
            '/images/quiz-images/1798177106/5.jpg',
            '/images/quiz-images/1798177106/3.jpg',
            '/images/quiz-images/1798177106/2.jpg',
            '/images/quiz-images/1798177106/1.jpg',
        ],
        fieldId: 1798177106,
    },
    {
        title: "איזה צורה של ספה שאתם הכי אוהבים",
        type: QuestionType.imageSelect,
        answers: Array.from(Array(10).keys()).map(x => x + 1),
        answersImages: [
            '/images/quiz-images/646980166/10.jpg',
            '/images/quiz-images/646980166/9.jpg',
            '/images/quiz-images/646980166/8.jpg',
            '/images/quiz-images/646980166/5.jpg',
            '/images/quiz-images/646980166/7.jpg',
            '/images/quiz-images/646980166/4.jpg',
            '/images/quiz-images/646980166/6.jpg',
            '/images/quiz-images/646980166/3.jpg',
            '/images/quiz-images/646980166/2.jpg',
            '/images/quiz-images/646980166/1.jpg',
        ],
        fieldId: 646980166,
    },
];

export default QuizQuestions;

export const findQuestion = (fieldId: number) => {
    return QuizQuestions.filter( (q, i) => {
        return (q.fieldId === fieldId)
    })[0];
}

export const getImageUrl = (fieldId: number, answer: number|string) => {
    const q = findQuestion(fieldId);
    const ans = Number(answer);
    if(q && q.type === QuestionType.imageSelect && q.answersImages) {
        return q.answersImages[ans];
    }
    return "";
}