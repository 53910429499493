export default class ThumbnailService {
    _images = new Map([])
    getImages(entryId: string, ks?: string) {
        if(this._images.has(entryId)) {
            const retPromise = new Promise( (resolve, reject)=> {
                if(this._images.has(entryId)) {
                    console.log("returning  images from array")
                    resolve(this._images.get(entryId))
                } else {
                    reject([])
                }
            })
            return retPromise
        }
        
        const retPromise = new Promise( (resolve, reject) => {
            fetch('https://www.kaltura.com/api_v3/service/thumbAsset/action/list', {
                method: 'post',
                body: JSON.stringify({
                    ks: ks,
                    format: 1,
                    filter: {
                        objectType: 'KalturaThumbAssetFilter',
                        entryIdEqual: entryId
                    }
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then( (result) => {
                result.json().then( (data) => {
                    this._images.set(entryId, data.objects)
                    console.log("added to map")
                    resolve(data.objects)
                })
            })
        })
        return retPromise;
    }
}