import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
  const d = new Date();
  const copyrightYear = d.getFullYear(); 

  return (
    <div className="footer">
      Copyright © {copyrightYear} Live in Style - All Rights Reserved. <Link to="/credits">credits</Link>
    </div>
  )
}

export default Footer;
