import React, { useState } from 'react';
import FieldDecorator from '../../components/fielddecorator/FieldDecorator';
import { useForm } from "react-hook-form";
import KalturaLoginService from '../../services/KalturaLoginService';
import KalturaEntriesService from '../../services/KalturaEntriesService';
import KalturaEntryBase from '../../types/KalturaEntryBase';
import EntryDisplay from '../../components/entrydisplay/EntryDisplay';
import ThumbnailService from "../../services/ThumbnailService";

import './admin.css';
// import RectangleLogo from '../../components/rectanglelogo/RectangleLogo';

const thumbService = new ThumbnailService()

export default function Admin() {
    const [loginStatus, setLoginStatus] = useState('')
    const [entriesFetched, setEntriesFetched] = useState(false)
    const emptyEntryList = new Map()
    const [entries, setEntries] = useState(emptyEntryList)
    const emptyFocusEntry: KalturaEntryBase = {
        id: '',
        name: '',
        description: JSON.stringify({})
    }
    const [focusEntry, setFocusEntry] = useState(emptyFocusEntry)

    const showEntry = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const a = event.currentTarget;
        console.log("going to display full details of "+a.id)
        const entry = entries.get(a.id)
        thumbService.getImages(entry.id, loginStatus ).then( (data: any) => {
            entry.images = data;
            console.log('fetched thumbs, set on obj:')
            console.log(entry.images);
            setFocusEntry(entry)
        })         
        event.preventDefault()
    }

    if(!loginStatus) {
        const localStorageKs = localStorage.getItem('adminKs')
        if(typeof localStorageKs === 'string') {
            setLoginStatus(localStorageKs)
        }
    }

    // TODO - check if form works without all the consts below
    // const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ mode: 'all'});
    const { register, handleSubmit } = useForm({ mode: 'all'});
    const onSubmit = (data: any) => {
        KalturaLoginService(data.email, data.password).then( (ks) => {
            setLoginStatus(ks)
            localStorage.setItem('adminKs', ks)
        })
    }

    const getEntries = () => {
        KalturaEntriesService(loginStatus).then( (entries) => {
            console.log("calling Kaltura");
            if(entries !== false) {
                var entriesAsObjList = new Map([])
                entries.objects.map( (entry: KalturaEntryBase) => {
                    entriesAsObjList.set(entry.id, entry)
                    return entry;
                })
                setEntriesFetched(true);
                setEntries(entriesAsObjList)    
            } else {
                localStorage.removeItem('adminKs');
                setLoginStatus('');
            }
        })
    }

    let content;
    if(loginStatus && !entriesFetched) {
        console.log("entries not fetched");
        content = (
          <div>
            <button className="active" onClick={getEntries}>טען רשימה</button>
          </div>
        )
    } else if (loginStatus && entriesFetched) {
        console.log("entries fetched");
        var entryList: JSX.Element[] = [];
        entries.forEach( (entry, entryId) => {
            const classes = 'button-link' + ((focusEntry.id === entryId)? ' active-entry': ''); 
            entryList.push(
              <li key={entryId}>
                <button className={classes} key={entryId} id={entryId} onClick={showEntry} >
                  {entry.name}
                </button>
                {/* <span className="entry-id">{entryId}</span> */}
              </li>
            )
        })
        content = (
          <>
                <div className="entries">
                <h3>שאלונים</h3>
                    <ul>
                        {entryList}
                    </ul>
                </div>
                <div className="focus">
                    { (focusEntry.id) && <button onClick={() => {setFocusEntry(emptyFocusEntry)}}>סגור</button> }
                    <EntryDisplay entry={focusEntry} />
                </div>
            </>
        )
    } else {
    // this is a case where user is not logged-in
    content = (
          <form onSubmit={handleSubmit(onSubmit)}>
              <FieldDecorator label={'אימייל'}><input {...register('email')} /></FieldDecorator>
              <FieldDecorator label={'סיסמא'}><input {...register('password')} type="password" /></FieldDecorator>
              <button className="active" type="submit" value="Login">Login</button>
          </form>
  )
    
    }
    let pageLayout = (
        <div className="adminpage">
        <div className="logo">
          {/* <RectangleLogo classes='adminpage' /> */}
          <div className="squarelogo-container">
            <img alt="Ayelet Radai Logo" src='/images/styl.ing.logo.jpeg' />
          </div>
          </div>
          <div className="content">
          {content}
          </div>
          </div>
    )
    return pageLayout;
    
}