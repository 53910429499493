import { useNavigate } from "react-router";
import About from "../../components/about/About";
import InfoButton from "../../components/infobutton/InfoButton";
import ProfilePic from "../../components/profilepic/ProfilePic";
import SquareLogo from "../../components/squarelogo/SquareLogo";
import WhatsappButton from "../../components/whatsappbutton/WhatsappButton";
import { isMobile } from "react-device-detect";

import './aboutpage.css';
import { useEffect } from "react";

const AboutPage = () => {
    const navigate = useNavigate();
    useEffect( () => {
        if(isMobile) {
            navigate('/');
        }    
    })
    return (
        <div className="aboutpage">
            <div className="rightrow">
                <ProfilePic />
                <SquareLogo />
            </div>
            <div className="leftrow">
                <h1>היי, נעים להכיר :)</h1>
                <div className="main-content about">
                    <About></About>
                </div>
                <div className="about-page-buttons">
                    <WhatsappButton classes="aboutpage-buttons" />
                    <InfoButton mobileLinkHref="#top" desktopLinkHref="/" buttonText="מבצע פסח 2023" classes="aboutpage-buttons" />
                </div>
            </div>
        </div>
    )
}
export default AboutPage;