import { Link } from "react-router-dom";

import './infobutton.css';

interface Props {
    classes?: string;
    buttonText: string;
    desktopLinkHref: string;
    mobileLinkHref: string;
}

const InfoButton = (props: Props) => {
    const classNames = 'info-button '+ props.classes;
    return (
        <div className={classNames}>
            <div className="info-button-icon"></div>
            <div className="info-button-text">
                <Link to={props.desktopLinkHref} className="desktop-inline">{ props.buttonText }</Link>
                <a href={props.mobileLinkHref} className="mobile-inline">{ props.buttonText }</a>
            </div>
        </div>
    )
}

export default InfoButton;