import { ChangeEvent, FormEvent, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import './getquizform.css';

const Getquizform = () => {
  const submitUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfFnShxoUv8Lg_eqNQXPjkTy_vu50F7iuTOYZ6cKWEDl1qTWg/formResponse';
  let navigate: NavigateFunction = useNavigate();
  const [nameValue, setName] = useState('');
  const [emailValue, setEmail] = useState('');
  const [showError, setShowError] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if(!(nameValue && emailValue)) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false)
      }, 3000);
      return;
    }
    const details = btoa(JSON.stringify({name: nameValue, email: emailValue}))
    const formData  = new FormData();
    formData.append('entry.320740386', nameValue);
    formData.append('entry.810360017', emailValue);
    fetch(submitUrl, {
      method: 'POST',
      mode: 'no-cors',
      body: formData
    }).then( (res) => {
      navigate('/styling-quiz?details='+details);
    })
  }
  return (
    <div className='getquizform'>
      <form>
        <div className="field">
          <label htmlFor="entry.320740386">שם:</label>
          <input type="text" name="entry.320740386"  onInput={ (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
        </div>
        <div className="field">
          <label htmlFor="entry.810360017">מייל:</label>
          <input type="email" name="entry.810360017" onInput={ (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
        </div>
        {showError && <div className="form-error">
          יש למלא שם וכתובת מייל תקינה
        </div>}
        <div className="field">
          <button onClick={ handleSubmit }>אני רוצה לגלות מה הסגנון העיצובי שלי!</button>
        </div>
      </form>
    </div> 
  )
}

export default Getquizform;
