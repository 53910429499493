import BackButton from "../BackButton";
import './terms.css';

const Terms = () => {
    return (
        <div className="terms-page">
            <h2>תנאי מבצע לכבוד פתיחת עסק ופסח</h2>
            <h3>1. השירות כולל מפגש של 3 שעות בבית הלקוח לצורך:</h3>
            <div className="terms-paragraph">
                <div className="paragraph-title underlined">
                פגישה עד 3 שעות בה:
                </div>
                <div>
                    <ol>
                    <li>אתרשם מהחלל הנבחר ונדבר על הצרכים שלכם</li>
                    <li>נאפיין את הקו העיצובי שלכם</li>
                    <li>נתכנן ונחלק את החלל בצורה אופטימלית</li>
                    <li>נבחר צבעים מתאימים לחלל ולקו העיצובי שלכם</li>
                    <li>נאפיין את הריהוט והאביזרים המתאימים</li>
                    </ol>
                </div>
                <div className="paragraph-title underlined">מה תקבלו לאחר הפגישה:</div>
                <div>
                    <ol>
                        <li>״מפת עיצוב״ - שרטוט של החלל עם תוכנית העמדת רהיטים, חומרים, צבעים ורפרנסים לעיצוב שבחרנו</li>
                        <li>רשימת קניות עם לינקים למוצרים מתאימים בסגנון והתקציב שלכם</li>
                    </ol>
                </div>
                <div className="paragraph-title">
                    <span className="underlined">מחיר מבצע - </span>
                    <span className="bold">549 ש״ח</span>
                </div>
                <div className="bold space-top-20">
                במידה ורוצים, ניתן להרחיב את החבילה כדי לכלול יום קניות בעלות כוללת של 1000 ש״ח
                </div>
                <div>
                    <ul>
                        <li>המחיר המוצע הינו עבור עיצוב לחדר אחד</li>
                        <li>יום קניות מוגדר כ - 3 שעות בהם נלך ביחד לחנויות נבחרות מראש כדי לרכוש את הפריטים הרלוונטיים</li>
                        <li>כל שעה נוספת בעלות של 70 ש״ח לשעה</li>
                    </ul>
                </div>
                <div className="">
                    <span className="bold">תנאי תשלום - </span>מזומן או ביט בסיום הפגישה.
                </div>
                <div className="">
                    <span className="bold">תקף המבצע - </span>01.04.2023
                </div>
                <div className="">
                    <span className="bold">תיאום פגישה - </span>בהתאם לפניות המעצבת
                </div>
            </div>
            <BackButton />
        </div>
    )
}

export default Terms;