export default async function KalturaEntriesService(ks: string) {
    const result = await fetch('https://www.kaltura.com/api_v3/service/media/action/list', {
        method: 'post',
        body: JSON.stringify({
            format: 1,
            ks,
            filter: {
                objectType: 'KalturaMediaEntryFilter',
                orderBy: '-createdAt',
                tagsLike: 'ayestylingsubmission',
                statusIn: '0,1,2,7',
            },
            pager: {
                objectType: 'KalturaFilterPager',
                pageSize: 500
            }
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const entryList = await result.json()
    if(entryList.objectType === 'KalturaAPIException') {
        return false;
    }
    return entryList
}