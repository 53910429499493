import './whatsappbutton.css';

interface Props {
    classes?: string;
}

const WhatsappButton = (props: Props) => {
    const classNames = 'whatsapp-info-about '+ props.classes;
    return (
        <div className={classNames}>
            <div className="whatsapp-info-about-icon whatsapp-info-about-whatsapp-icon"></div>
            <div className="whatsapp-info-about-text">
                <a href="https://wa.me/972508853353" target="_blank" rel="noreferrer">שלחו לי הודעה</a>
            </div>
        </div>
    )
}

export default WhatsappButton