import { Link } from 'react-router-dom';
import './squarelogo.css';

const SquareLogo = () => {
    return (
        <div className="squarelogo-container">
            <Link to="/"><img alt="Ayelet Radai Logo" src='/images/logo-box.png' /></Link>
        </div>
    )
}

export default SquareLogo;