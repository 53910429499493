import './checkboxdecorator.css';

interface AppProps {
    label: string;
    children: JSX.Element;
    isRadio?: boolean;
};

function CheckboxDecorator(props: AppProps) {
    const classes = "checkmark" + ((props.isRadio)? ' radio': '');
    return (
        <div className="checkboxdecorator">
            <label className="container">{props.label} 
                {props.children}
                <span className={classes}></span> 
            </label>
        </div>
    )
}

export default CheckboxDecorator