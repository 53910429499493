import { FieldErrors } from 'react-hook-form';
import './fielddecorator.css';

type AppProps = {
    label: string;
    children: JSX.Element,
    formErrors?: FieldErrors;
    isHidden?: boolean
  };
function FieldDecorator(props: AppProps) {
    let classNames = 'field' + (props.isHidden? ' hidden': '');
    const label = props.label.replace(/!!([^\u0000-\u007F]+)!!/g, "<u>$1</u>");
    return (
        <div className="fielddecorator">
            <div className={classNames}>
                <dt dangerouslySetInnerHTML={{__html: label}}></dt>
                <dd>{props.children}</dd>
                {props.formErrors && props.formErrors.name && props.formErrors.name.type === "required" && <span className='formError'>שכחת למלא שדה זה...</span>}
            </div>
        </div>
    )
}

export default FieldDecorator