import { FormEvent, useEffect, useState } from "react";
import RectangleLogo from "../../components/rectanglelogo/RectangleLogo";
import QuizQuestions, { findQuestion, getImageUrl, QuestionType } from './QuizQuestions';
import './stylingquiz.css';
interface Details {
  name?: string,
  email?: string
}
// interface Answer {
//   fieldId: number,
//   answer: string|number 
// }

const Stylingquiz = () => {
  const [details, setDetails] = useState<Details>({});
  const [detailsValid, setDetailsValid] = useState(false);
  const [parsingGetOnce, setParsingGetOnce] = useState(false);
  const [detailsHash, setDetailsHash] = useState('');

  const [questionsAnswered, setQuestionsAnswered] = useState<number>(0);
  const [answers, setAnswers] = useState<Map<number, string|number>>(new Map<number, string|number>());

  const [showThankyou, setShowThankyou] = useState(false);

  const TOTAL_QUESTIONS = 10;
  const submitUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdUu_Ly7atTd8OMPPuRJEyH0Jluf3g79sarG73VK69xys6hEg/formResponse';

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData  = new FormData();
    const answersIterator = answers.entries();
    let a;
    formData.append('entry.1900366157', details.name?? '');
    formData.append('entry.779349678', details.email?? '');
    let moreFields = true;
    while(moreFields) {
      try {
        a = answersIterator.next().value;        
      } catch(error) {
        moreFields = false;
        continue;
      }
      if(!a) {
        moreFields = false;
        continue;
      }
      formData.append('entry.'+a[0], a[1]);
    }
    fetch(submitUrl, {
      method: 'POST',
      mode: 'no-cors',
      body: formData
    }).then( (res) => {
      setShowThankyou(true);
      // clear session storage.
      window.sessionStorage.removeItem(detailsHash);
    })
  }

  const answerQuestion = (e: React.MouseEvent<HTMLDivElement>) => {
    const fieldId = Number(e.currentTarget.getAttribute('data-fieldid'));
    const answer = String(e.currentTarget.getAttribute('data-answer'));
    // const answerObj: Answer = {
    //   fieldId,
    //   answer
    // }
    
    const newAnswers = new Map(answers);
    newAnswers.set(fieldId, answer);
    console.log(newAnswers);
    setAnswers(newAnswers);
    window.sessionStorage.setItem(detailsHash, JSON.stringify(Array.from(newAnswers.entries())));
    const questionElement: HTMLElement|null = document.getElementById("question-"+(fieldId));
    if(questionElement !== null) {
      const currentTarget = e.currentTarget;
      questionElement.classList.add('question-removal');
      setTimeout( () => {
        questionElement.classList.remove('question-removal');
        currentTarget.classList.remove('selected-answer');
        setQuestionsAnswered(questionsAnswered+1);
      }, 1000);
    }
    e.currentTarget.classList.add('selected-answer');
  }

  useEffect( () => {
    if(!parsingGetOnce) {
      setParsingGetOnce(true);
    } else {
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const details = urlParams.get('details')?? false;
    if(details) {
      try {
        const parsedDetails = JSON.parse(atob(details));
        if(Object.hasOwn(parsedDetails, 'name') && Object.hasOwn(parsedDetails, 'email')) {
          setDetailsHash(details);
          setDetailsValid(true);
          setDetails(parsedDetails);
        }
        const sessionAnswers = window.sessionStorage.getItem(details);
        if(sessionAnswers) {
          const sessionAnswersObj = JSON.parse(sessionAnswers);
          const sessionAnswersMap = new Map<number, string|number>(sessionAnswersObj);
          setAnswers(sessionAnswersMap);
          setQuestionsAnswered(sessionAnswersMap.size);
        }
      } catch(err) {
        console.log("error parsing json or atob");
      }
    }
  }, [parsingGetOnce])

  const questions = QuizQuestions.filter( (qq, ii) => { 
    return (ii === questionsAnswered)}).map( (q, i) => {
      const isImageAnswer = (q.type === QuestionType.imageSelect);
      const answerClass = (isImageAnswer)? 'image-answer': 'text-answer';
      const allAnswers: Array<JSX.Element> = q.answers.map( (a, ai) => {
        const previousAnswer = answers.get(q.fieldId);
        let isSelected = false;
        if(previousAnswer && previousAnswer === a) {
          isSelected = true;
        }
        let answerImgSrc = '';
        if(isImageAnswer) {
          if(q.answersImages && q.answersImages.length > 0) {
            answerImgSrc = q.answersImages[ai];
          }  
        }
        return(
          <div className={answerClass+" answer "+ ((isSelected)? 'selected-answer': '')} key={ai} onClick={answerQuestion} data-fieldid={q.fieldId} data-answer={a}>
            { !isImageAnswer && <div><input type="checkbox" /> {a}</div>}
            { isImageAnswer && <><img alt={("q-"+i+"ans-"+ai)} src={answerImgSrc} /><input type="checkbox" /></> }
          </div>
        )
      })
      return (
      <div className={q.type.toString()+ " question"} key={i} id={"question-"+q.fieldId}>
        <h2>{q.title}</h2>
        <div className="answers">
        {allAnswers}
        </div>
      </div>
    )
  })
  const questionsMenu = QuizQuestions.map( (q, i) => {
    const buttonClass = (i === questionsAnswered)? 'active' : '';
    const buttonDisabled = (i > answers.size);
    return <div className="question-link"><button disabled={buttonDisabled} className={buttonClass} onClick={ ()=> {setQuestionsAnswered(i)}}>{i+1}</button></div>
  });
  questionsMenu.push(<div className="question-link done-link"><button disabled={ (answers.size !== TOTAL_QUESTIONS) } className={ (questionsAnswered === TOTAL_QUESTIONS)? 'active': ''} onClick={ ()=> {setQuestionsAnswered(TOTAL_QUESTIONS)}}>סיימתי</button></div>)
  let questionsReview = [<></>];
  if(questionsAnswered === TOTAL_QUESTIONS) {
    questionsReview = Array.from(answers.entries()).map( (ans, indx) => {
      const q = findQuestion(ans[0]);
      const isImage = q.type === QuestionType.imageSelect;
      return (
        <div className="answer-review">
          <div className="question">{q.title}</div>
          <div className="answered">
            { isImage && <img src={getImageUrl(q.fieldId, ans[1])} alt="answer for question" /> }
            { !isImage && <span>{ans[1]}</span> }
          </div>
        </div>
      );
    })
  }
  return (
    <div className="Stylingquiz">
      <RectangleLogo />
      { !showThankyou && 
      <>
        <h1>מה הסגנון העיצובי שלי?</h1>
        <div className="quiz-questions-menu">
          { questionsMenu }
        </div>
        { detailsValid && 
          <form>
            <input type="hidden" name="entry.1900366157" value={details.name} />
            <input type="hidden" name="entry.779349678" value={details.email} />
            {questions}
            { (questionsAnswered === TOTAL_QUESTIONS) && 
            <div className="send-screen">
              <h3>התשובות שלי:</h3>
              {questionsReview}
              <button type="submit" onClick={handleSubmit}>שלח</button>
            </div>
            }
          </form>
        }
        </>
      }
      { showThankyou && 
      <div className="thankyou">
        Thank you for taking the quiz. your results will be in your email.
      </div>}
      { !detailsValid && <div>Sorry, something went wrong </div>}
    </div>
  )
}

export default Stylingquiz;