import Backendless from "backendless"

  /*******************************************************************
  * KalturaEntryService.js
  * Generated by Backendless Corp.
  ********************************************************************/
		
const Utils = {
  isObject : obj => obj === Object(obj),
  isString : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'String',
  isNumber : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Number',
  isBoolean: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Boolean',
  isDate   : obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Date'
}

Backendless.APIServices.KalturaEntryService = {
  addEntry(name,data,uploadKs) {   if (typeof name !== 'string') {
        throw new Error('Invalid value for argument "name". Must be an string object')
      }
        
    if (!Utils.isObject(data)) {
      throw new Error('Invalid value for argument "data". Must be object value')
    }
      if (typeof uploadKs !== 'string') {
        throw new Error('Invalid value for argument "uploadKs". Must be an string object')
      }
      
    const args = {
      name: name,
        data: data,
        uploadKs: uploadKs
    }
      
    return Backendless.APIServices.invoke('KalturaEntryService', 'addEntry', args)
  }
}

export default Backendless.APIServices.KalturaEntryService