export const OperationType = {
  CREATE         : 'CREATE',
  CREATE_BULK    : 'CREATE_BULK',
  UPDATE         : 'UPDATE',
  UPDATE_BULK    : 'UPDATE_BULK',
  DELETE         : 'DELETE',
  DELETE_BULK    : 'DELETE_BULK',
  UPSERT         : 'UPSERT',
  UPSERT_BULK    : 'UPSERT_BULK',
  FIND           : 'FIND',
  ADD_RELATION   : 'ADD_RELATION',
  SET_RELATION   : 'SET_RELATION',
  DELETE_RELATION: 'DELETE_RELATION',
}

export const IsolationLevelEnum = {
  READ_UNCOMMITTED: 'READ_UNCOMMITTED',
  READ_COMMITTED  : 'READ_COMMITTED',
  REPEATABLE_READ : 'REPEATABLE_READ',
  SERIALIZABLE    : 'SERIALIZABLE',
}
