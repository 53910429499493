import './profilepic.css';

const ProfilePic = () => {
  return (
    <div className="my-picture">
      <img alt="Ayelet Radai Portrait" src='/images/aye.png' />
    </div>
  )
}

export default ProfilePic;
