const FurnitureDescriptions = [
  'זולים',
  'יקרים',
  'יפים',
  'פרקטיים',
  'תוצרת הארץ',
  'תוצרת חוץ',
  'ממותג מוכר',
  'איכותיים',
]

  export default FurnitureDescriptions