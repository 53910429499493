import React, { useState } from 'react';

import { useForm } from "react-hook-form";
import FieldDecorator from '../../components/fielddecorator/FieldDecorator';
import CheckboxDecorator from '../../components/checkboxdecorator/CheckboxDecorator';
import { getQuestionsRequireImages, Questions, QuestionTypes, Sections } from '../../data/Questions';
import Backendless from 'backendless'
import KalturaAuthService from '../../services/KalturaAuthService'
import KalturaEntryService from '../../services/KalturaEntryService'
import FileUpload from '../../components/fileupload/FileUpload';

import './stylingform.css';
import RectangleLogo from '../../components/rectanglelogo/RectangleLogo';
// import { isQualifiedName } from 'typescript';

Backendless.initApp("1AF01521-5124-5F0C-FF2D-5552E20DD100", "4DF7726C-ACA8-4D4B-B47D-B1AE57DEF1E8");

function StylingForm() {
    const [section, setSection] = useState(1);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState(new Map([]));
    const [createdEntryId, setCreatedEntryId] = useState('');
    const [uploadKs, setUploadKS] = useState('');
    
    // TODO - check if useForm is OK dropping watch const
    // const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ mode: 'all'});
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'all'});
    const onSubmit = (data: any) => {
      const tempFormData = new Map([]);
      Object.entries(data).forEach( (a, q) => {
        tempFormData.set(a[0], a[1])
      })
      setFormData(tempFormData);
      KalturaAuthService.getKs().then( (ks: string) => {
        setUploadKS(ks)
        const name = "Styling Submission from "+data.email;
        KalturaEntryService.addEntry(name, data, ks).then( ( result: any ) => {
          setCreatedEntryId(result.id);
          setFormSubmitted(true);
          setSection(4);    
        })
      })
    }
    
    const maxSection = Sections.length;
    const sectionButtons = Sections.map( ( sec, index ) => {
      let realIndex = index+1;
      var disabled = false;
      let isActive = false;
      if(realIndex === section) {
        disabled = true; // current section. not clickable.
        isActive = true;
      }
      if(!formSubmitted && realIndex === maxSection) {
        disabled = true; // didn't send the form yet. not showing step 4 (images)
      }
      if(formSubmitted && realIndex !== maxSection) {
        disabled = true; // form submitted... now the only possible section is 4.
      }
      return (<button key={index} disabled={disabled} className={(isActive? 'active-section': '')} onClick={ () => { setSection(realIndex)}} >{ Sections[index]}</button>)
    })

    const questionsWithImages = getQuestionsRequireImages();
    
    return (
      <div className="stylingform">
        <RectangleLogo classes='stylingform'/>
        <h1>שאלון לקראת פגישת עיצוב</h1>
        <div>לקראת הפגישה שלנו, אשמח אם תקחו כמה דק׳ לענות על השאלות בטופס הבא.</div>
        <div>לאחר מילוי השאלות ולחיצה על ״שלח״ (למטה) תוכלו לעבור לשלב העלאת תמונות.</div>
        <div className='section-buttons'>
        {
          sectionButtons
        }
        </div>
        <h2>{Sections[section-1]}</h2>
        { (section !== maxSection) && 
        <form onSubmit={handleSubmit(onSubmit)}>
          {
          Questions.map( (q, i) => {
            if(q.section !== section) return (<div key={i}></div>)
            if(q.options) {
              return (
                <FieldDecorator key={i} isHidden={ (q.section !== section)} label={q.label} formErrors={errors}>
                  <div className="checkboxes">
                    { 
                      q.options.map((word, key) => {
                        const isRadio = (q.type === QuestionTypes.radio);
                        return <CheckboxDecorator key={key} label={word} isRadio={isRadio}><input {...register(q.name, {required: true})} type={ q.type } value={word}  /></CheckboxDecorator>
                      })
                    }
                  </div>
                </FieldDecorator>
                )
            } else if(q.type === QuestionTypes.textarea ) {
              return ( <FieldDecorator key={i} isHidden={ (q.section !== section)} label={q.label} formErrors={errors}><textarea {...register(q.name, {required: true})} /></FieldDecorator> )
            } else {
              return (<FieldDecorator key={i} isHidden={ (q.section !== section)} label={q.label} formErrors={errors}><input type={ q.type?? "text" } {...register(q.name, {required: true})} /></FieldDecorator>)
            }
          })
          }
          {
            sectionButtons
          }
          <FieldDecorator label="">
            { (section === 3)?  
            <div>
              <input className="active" type="button" onClick={ (e) => { setSection(section-1); e.preventDefault(); return false }} value="&lt; הקודם"></input>
              <input className="active" disabled={(!isValid)} type="submit" value="שלח"></input>
            </div>
              
            : 
            <div>
              { (section > 1) ?
              <input className="active" type="button" onClick={ (e) => { setSection(section-1); e.preventDefault(); return false }} value="&lt; הקודם"></input>
              : <></>}
              <input className="active" disabled={(!isValid)} type="button" onClick={ (e) => { setSection(section+1); e.preventDefault(); return false }} value="הבא &gt;"></input>
            </div>
          }
          </FieldDecorator> 

        </form>
        }
        { (section === maxSection) && 
          <div>
            <div>כדי שאלמד אתכם על הצד הטוב ביותר, חשוב לבחור תמונות של חללים מעוצבים ולא תמונות של פריטים בודדים!</div>
            <hr />
            {
              questionsWithImages.map( (q,i) => {
                const questionName = q.name;
                console.log(formData)
                console.log(q.name);
                let answers = formData.get(questionName);
                console.log(answers)
                let answersString: string = ''
                if(answers instanceof Array) {
                  answersString = answers.join(', ');
                } else if(typeof answers === 'string') {
                  answersString = answers;
                }
                return (
                  <div key={i}>
                    <h4>עבור השאלה - <span>{q.label} - ניתן להעלות עד 10 תמונות:</span></h4>
                    <h5>תשובתכם: {answersString}</h5>
                    <h5>{q.imagesDescription}</h5>
                    <FileUpload  entryId={ createdEntryId  } ks={uploadKs} thumbTag={q.name} limit={10} /> 
                    <hr />
                  </div>
                )
              })
            }
            <div>אנא העלו 6-8 תמונות השראה של חללים מעוצבים שאהבתם</div>
            <div>כדי שאלמד אתכם על הצד הטוב ביותר, חשוב לבחור תמונות של חללים מעוצבים ולא תמונות של פריטים בודדים!</div>
            <FileUpload  entryId={ createdEntryId  } ks={uploadKs} limit={5} thumbTag="general" /> 
          </div>
        }
      </div>
    )
}

export default StylingForm