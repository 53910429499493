import { ReactNode, useCallback, useEffect, useState } from "react";
import RectangleLogo from "../../components/rectanglelogo/RectangleLogo";

import './home.css';

interface Props {
    mobileChildren?: ReactNode;
}

const Home = (props: React.PropsWithChildren<Props>) => {
    const localStorageKey = 'randomBgV1';
    // const imageNames = new Map<number, string>([
    //     [1, "/images/modern-luxury-authentic-dining-room-interior-design.jpg"],
    //     [2, "/images/luxury-authentic-dining-room-interior-design.jpg"],
    //     [3, "/images/tropical-authentic-dining-room-interior-design-with-gallery-wall.jpg"],
    // ]);
    // alternating backgrounds
    const [backgroundClass, setBackgroundClass] = useState('home first');
    const [mobileCoverImage, setMobileCoverImage] = useState(1)
    const classOptions = ['first', 'second', 'third'];
    const decideImageFromLocalStorage = useCallback( () => {
        const ls = window.localStorage.getItem(localStorageKey);
        const data = ls? JSON.parse(ls): {};
        if(data.expiry > Math.floor((Date.now()/1000))) {
            setBackgroundClass(data.cls);
            setMobileCoverImage(data.imgNumber);
        } else {
            const random = Math.floor(Math.random() * classOptions.length);
            const res = 'home '+classOptions[random];
            const expiry = Math.floor((Date.now()/1000)+86400);
            window.localStorage.setItem(localStorageKey, JSON.stringify( { cls: res, expiry, imgNumber: random+1} ));
            setBackgroundClass(res);
            setMobileCoverImage(random+1);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        decideImageFromLocalStorage()
    }, [decideImageFromLocalStorage]);

    console.log(mobileCoverImage);
    return (
        <div className={backgroundClass}>
            <RectangleLogo />
            <div className="mobile-cover">
            </div>
            <div className="main-content">
                { props.children }
                <div className="mobile-content">
                    { props.mobileChildren }
                </div>
            </div>
        </div>
    )
}
export default Home;