import './about.css';

const About = () => {
    return (
        <div className="about-page" id="about">
            <div className="about-paragraph">
            אני איילת רדעי, מעצבת פנים ומלבישת בתים, סטייליסטית ובעבר גם מעצבת בתחום האופנה והטקסטיל.
            </div>
            <div className="about-paragraph">
            אמא לאמילי, שירלי ויואב, נשואה לגונן וגרה ברמת גן.
            </div>
            <div className="about-paragraph">
            עיצוב תמיד היה חלק בלתי נפרד מחיי, למדתי עיצוב אופנה בשנקר, סטיילינג אישי בסטייל גורו ועיצוב פנים והום סטיילינג בסטודיו פופה.
            </div>
            <div className="about-paragraph">
            היום אני מתמחה ביצירה של מתחמים ובתים מעוצבים בכל תקציב.
            </div>
            <div className="about-paragraph">
            בתהליך קצר ומדויק , אשמח לעזור לכם ליצור מרחב מעוצב, עם פתרונות אחסון יעילים ויפים בהתאמה אישית לצרכים ולתקציב שלכם.  
            </div>
        </div>
    )
}

export default About;