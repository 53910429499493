const StylingWords = [
    'אקלקטי',
    'בהיר',
    'יוקרתי',
    'אקסלוסיבי',
    'רציני',
    'פשוט',
    'אפריקאי',
    'מודרני',
    'כהה',
    'מלון בוטיק',
    'אתני',
    'צעיר',
    'מאופק',
    'מרוקאי',
    'הייטק',
    'לבן',
    'כפרי',
    'רומנטי',
    'בוגר',
    'טבעי',
    'צרפתי',
    'תעשייתי',
    'ניו יורקי',
    'קר',
    'דרמתי',
    'חשוך',
    'מינימליסטי',
    'אנגלי',
    'צבעוני',
    'עמוס',
    'חם',
    'משפחתי',
    'מואר',
    'הודי',
    'גברי',
    'רטרו',
    'אלגנטי',
    'קאריבי',
    'קליל',
    'רשמי',
    'שונה',
    'נשי', 
  ]

  export default StylingWords