import { Link } from 'react-router-dom';
import './rectanglelogo.css';

interface Props {
  classes?: string;
}

const RectangleLogo = (props: Props) => {
  const classNames = "logo-container "+props.classes;
  return (
    <div className={classNames}>
      <Link to="/"><img alt="Ayelet Radai Logo" src='/images/logo.png' /></Link>
    </div>
  )
}

export default RectangleLogo;
