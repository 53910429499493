const Materials = [
  'עץ אלון',
  'עץ אגוז',
  'במבוק',
  'טפט',
  'נירוסטה מבריקה',
  'ברזל שחור',
  'בטון',
  'זכוכית',
  'גימור מבריק',
  'ניקל מוברש',
  'גימור מט',
  'זהב',
  'בריקים',
  'טיח',
  'פרקט',
  ]

  export default Materials