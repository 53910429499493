export default async function KalturaLoginService(email: string, password: string) {
    const result = await fetch('https://www.kaltura.com/api_v3/service/user/action/loginByLoginId', {
        method: 'post',
        body: JSON.stringify({
            format: 1,
            loginId: email,
            password,
            partnerId: 9008,
            privileges: 'disableentitlement,disableentitlements'
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const ks = await result.json()
    return ks;
}