import { Link } from "react-router-dom";

const BackButton = () => {
    return (
        <div className="back-button">
            <Link to="/">חזרה</Link>
        </div>
    )
}

export default BackButton;