import React, {useState} from "react";
import KalturaEntryBase from "../../types/KalturaEntryBase";
// import {getQuestionsRequireImages, Questions} from '../../data/Questions'
import {Questions} from '../../data/Questions'

import './entrydisplay.css';

interface EntryDisplayProps {
    entry: KalturaEntryBase,
}
function getImages(images: [], tag: string, clickHandler: React.MouseEventHandler<HTMLImageElement>): JSX.Element[] {
    const baseUrl = "https://cfvod.kaltura.com/api_v3/" +
        "service/thumbasset/action/serve/"+
        "thumbParams:objectType/KalturaThumbParams/thumbParams:cropType/1/"+
        "thumbParams:width/150/thumbParams:height/150/thumbAssetId/"
    const returnedElements: JSX.Element[] = [];
    const questionImages = images.filter( (asset: any) => { 
        const tags = asset.tags.split(',');
        return tags.includes(tag);
    });

    questionImages.forEach( (asset: any) => {
        const assetUrl = baseUrl+asset.id
        returnedElements.push(<img key={asset.id} alt={asset.id} src={ assetUrl }  onClick={clickHandler} id={asset.id} />)
    })
    return returnedElements;
}
export default function EntryDisplay(props: EntryDisplayProps) {
    const [modalImageShowing, toggleModal] = useState(false)
    const [modalImageUrl, setModalImage] = useState('')


    const handleImageClick = (event: React.MouseEvent<HTMLImageElement>): void => {
        console.log(event.currentTarget.src)
        var url = event.currentTarget.src
        url = url.replace('thumbParams:width/150/thumbParams:height/150', '')
        console.log(url)
        setModalImage(url)
        window.scrollTo(0,0);
        toggleModal(!modalImageShowing)
    }
    const closeModal = () => {
        console.log("called ")
        console.log( Number(modalImageShowing))
        toggleModal(!modalImageShowing)
    }
    // TODO - what is here for?
    // const questionsWithImages = getQuestionsRequireImages();
    if(props.entry.id) {
        const formData = JSON.parse(props.entry.description)
        const answers = Questions.map( (q, i) => {
            const key = q.name;
            let answer = formData[key];
            if(answer instanceof Array) {
                answer = answer.join(", ");
            }
            var questionImages;
            if(q.requiresImages && props.entry.images) {
                questionImages = getImages(props.entry.images, q.name, handleImageClick)
            }
            const label = q.label.replace(/!!([^\u0000-\u007F]+)!!/g, "<u>$1</u>");
            return (
                <div key={i} className="answer-line">
                    <span className="question"><b dangerouslySetInnerHTML={{__html: label}}></b></span><span className="answer">{answer}</span>
                    { (questionImages) && <div>{questionImages}</div> }
                </div>
            )
        })
        var imgs: JSX.Element[] = [];
        if(props.entry.images) {
            imgs = getImages(props.entry.images, 'general', handleImageClick)
        }
        return (
            <div className="entry-display">
                {answers}
                <div className="general-images">
                    {imgs}
                </div>
                <div className={(modalImageShowing)? 'image-modal': 'image-modal hidden'}>
                    <button onClick={closeModal}>X</button>
                    <div className="modal-image-holder">
                        <img alt="large display of inspiration" src={modalImageUrl} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (<div></div>)
    }
}