import { useEffect, useRef } from "react";

import './quizlanding.css';
import RectangleLogo from "../../components/rectanglelogo/RectangleLogo";
import ProfilePic from "../../components/profilepic/ProfilePic";
import Testimonial from "../../components/testimonial/testimonial";
import Getquizform from "../../components/getquizform/Getquizform";

const Quizlanding = () => {
  const formSection = useRef<null | HTMLElement>(null);
  useEffect( () => {

  })

  const jumpToForm = () => {
    if(formSection.current != null) {
      const element = formSection.current;
      element.scrollIntoView({behavior: 'smooth'});
    }
  }
  return (
    <div className="quizlanding">
      <section className="top">
        <div className="landing-page-logo-container">        
          <RectangleLogo classes="landing-page-logo"></RectangleLogo>
        </div>
        <div className="cta">
          <h2>איילת רדעי מציגה את השאלון החינמי הראשון שיעזור לך לעצב את הבית</h2>
          <h1>מה הסגנון העיצובי שלי</h1>
          <div>איך לדייק את עצמך ולעצב את הבית  לפי הסגנון האישי שלך</div>
          <div>(מבלי לחשוש, ללכת לאיבוד בפינטרסט או בחנויות לעיצוב הבית)</div>
          <button onClick={jumpToForm}>אני רוצה לגלות מה הסגנון העיצובי שלי!</button>
        </div>
      </section>      
      <section className="why">
        <div className="cta">
          <div>
            <ul>
              <li>אם רכשת דירה חדשה ואין לך מושג איזה רהיטים להעביר ואיזה לקנות חדש</li>
              <li>או בא לך להחליף את הספה ועל הדרך לרענן קצת את הסלון</li>
              <li>אולי העדפת לגור בדירה שכורה ובא לך להפוך אותה לבית נעים בתקציב נמוך</li>
              <li>יכול להיות שאין לך בכלל כסף לשיפוץ עכשיו אבל משהו חייב להשתנות בבית</li>
              <li>ואולי רק רוצה את הטאצ׳ הזה שיגרום לבית שלך להרגיש מעוצב.</li>
            </ul>
          </div>
          <h2>השאלון הזה הוא בדיוק בשבילך!!!</h2>
          <div className="bottom-20">בכמה שאלות קצרות נגלה לך:</div>
          <div className="attribute-visuals">
            <div className="attribute">
              <div className="attribute-visual question"></div>
              <div className="attribute-text">איך קוראים לסגנון העיצובי שלך- לדבר במונחים מקצועיים שיעזרו לך להתמצא בחנויות ובאינטרנט.</div>
            </div>
            <div className="attribute">
              <div className="attribute-visual list"></div>
              <div className="attribute-text">מה המאפיינים של הסגנון המועדף עליך- מה זה בכלל אומר סגנון מודרני או תעשייתי? ואיך אפשר לזהות בקלות את הפרטים שמתאימים לסגנון שלך.</div>
            </div>
            <div className="attribute">
              <div className="attribute-visual progress"></div>
              <div className="attribute-text"> האם יש לך ״תת-סגנון״ - כי לא תמיד יש תשובה אחת לשאלה.</div>
            </div>
            <div className="attribute">
              <div className="attribute-visual abc"></div>
              <div className="attribute-text">מה מילות המפתח לחיפוש השראה בפינטרסט שתואמת בדיוק את הסגנון שלך.</div>
            </div>
            <div className="attribute">
              <div className="attribute-visual design"></div>
              <div className="attribute-text">איזה סוג של תאורה, צבע ואביזרים כדי לך לשלב בבית.</div>
            </div>
          </div>
          <button onClick={jumpToForm} className="bottom-20">אני רוצה לגלות מה הסגנון העיצובי שלי!</button>
        </div>
      </section>
      <section className="who">
        <h2>למי זה מתאים:</h2>
        <div className="cta">
          <div>
            <ul>
              <li>אם עיצוב  ואסתטיקה חשובים לך ויש לך ״את זה״ אבל מרוב אהבות קשה לך לבחור</li>
              <li>אם אין לך מושג בעיצוב פנים אבל יש לך צורך לשנות מהו בבית</li>
              <li>אם רכשת דירה מקבלן או יד שנייה ואין לכך כרגע תקציב למעצבת</li>
              <li>אם סתם בא לך לגלות משהו חדש על עצמך</li>
            </ul>
          </div>
          <div className="button-placement">
            <button onClick={jumpToForm}>השאלון הזה הוא בדיוק בשבילך!!!</button>
          </div>
        </div>
      </section>
      <section className="how" ref={formSection}>
        <div className="cta">
          <h2>איך זה עובד:</h2>
          <div className="text">
            <div>אני יצרתי עבורך שאלון כיפי ומיוחד שיעזור לך לגלות מה העדפות שלך. </div>
            <div>כל מה שעליך לעשות זה למלא את הפרטים בתופס למטה ומייד הוא יפתח. </div>
            <div>את התשובה המפורטת והייחודית שלך אני שולחת ישר למייל, והיא כוללת בין השאר את שם הסגנון, מה המאפיין שלו, איזה צבעוניות הכי מתאימה לו ואיך למצוא חומר השראה לסגנון שלך באינטרנט.</div>
          </div>
          <Getquizform></Getquizform>
          {/* <button onClick={jumpToForm} className="bottom-20">אני רוצה לגלות מה הסגנון העיצובי שלי!</button> */}
        </div>
      </section>
      <section className="about">
        <div className="cta">
          <h2>אם זו הפעם הראשונה שאנחנו נפגשות</h2>
          <h2>נעים להכיר =)</h2>
          <ProfilePic></ProfilePic>
        </div>
      </section>
      <section className="about-text">
        <div className="cta">
          <div>אני איילת רדעי,</div>
          <div>מעצבת פנים ומלבישת בתים. אוהבת עיצוב ולעזור לאנשים להגשים את החלום הזה שנקרה בית.</div>
          <div>גם כשעברתי בפעם הראשונה מבית הורי, גם בפעם הראשונה עיצבתי חדר לבתי הבכורה, גם בבית הראשון שקנינו וגם בבית הנוכחי שאנחנו שוכרים, תמיד היה לי חשוב ליצור אווירה נעימה ואוטנטית, כזאת שלא רק יפה, אלה גם פונקציונאלית ומייצגת אותי ואת מה שאני אוהבת. וכן לרוב בתקציב מאוד מוגבל.</div>
          <div>לכן, גם אחרי שסיימתי לימודים לעיצוב פנים והום סטיילניג (בסטודיו פופה המהמם!) החלטתי שזאת השליחות שלי: </div>
          <div>לעבוד עם חלל קיים, להוציא ממנו את המיטב, גם בתקציב נמוך.</div>
          <div>נסיון החיים והתעסוקתי לימדו אותי לעבוד עם מה שיש ולהפוך כל חלל לנעים, חמים עם אווירה טובה, כזה שכיף לחזור אליו.</div>
          <div className="bottom-20"></div>
          <div>כחלק מהעבודה שלי אני מגלה יותר ויותר ויותר שהיכולת לתת שם למחשבות, אהבות והעדפות של הלקוחות שלי משמעותי בהרכבת הפאזל ליצירת בית חלומותם.</div>
          <div>לכן החלטתי לשתף אתכם בידע שלי.</div>
          <div>אני מאמינה שברגע שתדעו להגדיר מה אתם אוהבים, ואיך זה בעצם מתבטא בתוך התחום של עיצוב פנים, תוכלו להתחיל לדייק את העיצוב אצלכם בסלון, בחדר שינה, במשרד וכל מקום שתרצו, אפילו בשולחן החג, וכך ליצור את האווירה שאתם חולמים עליה.</div>
          <div>אני רואה בעיצוב ביטוי אישיות ויחודיות, וחושבת שלכל אחד מגיע לגור בחלל שנעים לו, לא משנה עם קונים הכל מהסטוק, איקאה, נגר פרטי או מעצב על.</div> 
        </div>
      </section>
      <section className="testimonials">
        <h2>לקוחות מספרים:</h2>
        <div className="testimonials-wrapper">
          <Testimonial names="שאולי וליאור רומן - ברדה">
          <div className="text">איילת יקירה,
                  חודשים של מחשבה מאומצת,
                  תכנון, הכנת תקציב, בחינת חומרים והמון אתגרים...
                  והנה, הגענו לרגע שבו כל העבודה הקשה משתלמת!
                  הכל מתחבר יחד לתמונה אחת הרמונית ויפה...
                  החלומות לובשים צורה 
                  והופכים למציאות!
                  אין לנו ספק שלא היינו עושים את הדרך בלעדייך ובטח לא היינו מגיעים להגשמה של כל החלומות.
                  בכל פינה שנביט בה
                  נראה את מגע הקסם שלך!
                  תודה לך על ההקשבה
                  תשומת הלב לכל הפרטים הקטנים והגדולים.
                  תודה על ההבנה ( בעיקר, ברגעים שאיבדנו את זה...)
                  תודה לך על העצות הטובות שחסכו לנו הרבה טעויות 
                  תודה על מי שאת!
                  מאחלת לכל מי שמעצב בית שתלווה אותו מעצבת מקצועית, נעימה, מוכשרת ורגישה כמוך!
                  אין כמוך!
                  מיוחדת במינה!
                  תודה, תודה ועוד אלף תודות!
                  מעריכים כל כך
            </div>
          </Testimonial>
          {/* <Testimonial names="חתולי ולימור"><div>הם אמרו שאני תותחית, ושאני מצויינת, ושממש היה כיף לעבוד איתי</div></Testimonial>
          <Testimonial names="נמרי וסימור"><div>הם אמרו שאני תותחית, ושאני מצויינת, ושממש היה כיף לעבוד איתי</div></Testimonial> */}
          <Testimonial />
          <Testimonial />
        </div>
      </section>
    </div>
  )
}

export default Quizlanding;